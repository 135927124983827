<template>
  <el-card class="box-login">
    <div class="header">
      <span>Login</span>
    </div>
    <div class="body">
      <div>
        <el-button  @click="useAuthProvider('google', Google)">Login dengan Google</el-button>
      </div>
      
      <el-form ref="form" :rules="rules" :model="form">
        <el-form-item label="Email" prop="username">
          <el-input placeholder="Email Anda" v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item v-if="!forget" label="Password" prop="password">
          <el-input
            placeholder="Password Anda"
            type="password"
            v-model="form.password"
          ></el-input>
        </el-form-item>
        <el-form-item class="margin-remove">
          <el-button
            style="width: 100%"
            :loading="loading"
            @click="handleLogin"
            type="primary"
            v-if="!forget"
          >
            Masuk
          </el-button>
          <el-button
            v-else
            style="width: 100%"
            :loading="loading"
            @click="handleForget"
            type="primary"
          >
            Kirim
          </el-button>
        </el-form-item>
        <el-button @click="forgetPassword" type="text">
          {{!forget ? 'Lupa Password ?' : 'Sudah Punya Akun ?'}}  
        </el-button>
      </el-form>
    </div>
    
    <div class="register-button">
      <div class="container">
       <div class="text-container">Dengan masuk, Anda setuju pada syarat dan ketentuan serta <el-button type="text" @click="goPrivacy">kebijakan privasi kami</el-button></div>
      </div>
      <span>Tidak mempunyai akun?</span>
      <el-button @click="goRegister" ghost size="small">Daftar Akun</el-button>
    </div>
  </el-card>
</template>

<script>
// Button M1
import { Providers} from 'universal-social-auth'

// Button M2
import { /*Github, Facebook*/ Google } from 'universal-social-auth'

export default {
  name: "login-component",
  data() {
    return {
      loading: false,
      forget: false,
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "Please input your username",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please input your password",
            trigger: "blur",
          },
        ],
      },
      googleSignInParams: {
        client_id: '366558832865-3l92en3b3n7edh75otbd3ev2mrahvqh1.apps.googleusercontent.com'
      },
      responseData: {},
      hash: '',
      data: {},
      fauth: false,
      Google,
    };
  },
  methods: {
    goPrivacy() {
      this.$router.push({ name: "Privacy" });
    },
    useAuthProvider (provider, proData) {
      const pro = proData

      const ProData = pro || Providers[provider]
      this.$Oauth.authenticate(provider, ProData).then((response) => {
        const rsp = response
        if (rsp.code) {
          this.responseData.code = rsp.code
          this.responseData.provider = provider
          this.useSocialLogin()
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    useSocialLogin () {
      // otp from input Otp form
      // hash user data in your backend with Cache or save to database
      const pdata = { code: this.responseData.code, otp: this.data.tok, hash: this.hash }
      this.loading = true;

        this.$store
          .dispatch("setLoginGoogle", pdata)
          .then((res) => {
            this.$notify({
              title: "Success",
              message: "Anda berhasil login!",
              type: "success",
            });
            this.loading = false;
            this.$router.push({ name: "Ppdb" });
          })
          .catch((err) => {
            this.$notify({
              title: "Error",
              message: "Login Gagal, Silahkan Coba Kembali!",
              type: "error",
            });
            this.loading = false;
          });

      // this.$axios.post('http://127.0.0.1:8000/api/v1/ppdb/sociallogin/' + this.responseData.provider, pdata).then(async (response) => {
      //     // `response` data base on your backend config
      //   if (response.data.status === 444) {
      //     this.hash = response.data.hash
      //     this.fauth = false // Option show Otp form incase you using 2fa or any addition security apply to your app you can handle all that from here

      //   }else if (response.data.status === 445) {
      //     //do something Optional

      //   }else {

      //     await this.useLoginFirst(response.data)
      //   }
      // }).catch((err) => {

      //   console.log(err)
      // })
    },
    async  useLoginFirst (e) {
    // this sample of to pust user data to my store
    console.log('userdata', e)
    },
    onSignInSuccess(googleUser) {
      // Handle success, e.g., get access token
      const profile = googleUser.getBasicProfile();
      console.log('ID: ' + profile.getId());
      console.log('Name: ' + profile.getName());
      // ...
    },
    onSignInError(error) {
      // Handle errors
      console.error('Error:', error);
    },
    goRegister() {
      this.$router.push({ name: "Register" });
    },
    forgetPassword() {
      this.forget = !this.forget;
    },
    handleLogin() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$notify({
            title: "Error",
            message: "Lengkapi Form Inputan!",
            type: "error",
          });
          return;
        }

        this.loading = true;

        this.$store
          .dispatch("setLogin", {
            username: this.form.username,
            password: this.form.password,
          })
          .then((res) => {
            this.$notify({
              title: "Success",
              message: "Anda berhasil login!",
              type: "success",
            });
            this.loading = false;
            this.$router.push({ name: "Ppdb" });
          })
          .catch((err) => {
            this.$notify({
              title: "Error",
              message: "Login Gagal, Silahkan Coba Kembali!",
              type: "error",
            });
            this.loading = false;
          });
      });
    },
    handleForget() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$notify({
            title: "Error",
            message: "Lengkapi Form Inputan!",
            type: "error",
          });
          return;
        }

        this.loading = true;

        this.$store
          .dispatch("forgetPassword", {
            username: this.form.username,
          })
          .then((res) => {
            this.$notify({
              title: "Success",
              message: "Email telah dikirim, mohon periksa email anda!",
              type: "success",
            });
            this.loading = false;
            this.forget = false;
          })
          .catch((err) => {
            this.$notify({
              title: "Error",
              message: "Gagal meminta password, Silahkan Coba Kembali!",
              type: "error",
            });
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="scss">
.box-login {
  width: 380px;

  .el-card__body {
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.body,
.header {
  padding: $space * 2;
}

.body {
  padding-top: 0;
  padding-bottom: 0;
}

.header {
  padding-bottom: 0;
}
.header {
  margin-bottom: $space * 2;
  span {
    font-weight: lighter;
    font-size: 2rem;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 30%;
      height: 4px;
      background-color: $green-color;
    }
  }
}

.register-button {
  background-color: darken(white, 4);
  padding: $space $space * 2;
  gap: $space / 2;
  display: flex;
  flex-direction: column;
  span {
    font-weight: bold;
    font-size: 0.875rem;
    color: darken(white, 60);
  }
  button {
    width: 100px;
  }
}

.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
}
</style>
